import React, { useState, useEffect } from "react"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./search.module.css"

import SearchPopUp from "./SearchPopUp"

import { useLocation } from "@reach/router"

const Search = () => {
	const classes = useStyle(defaultClasses)

	const [isOpen, setIsOpen] = useState(false)

	//get the location, if it changes close search
	const location = useLocation()
	useEffect(() => {
		setIsOpen(false)
	}, [location])

	return (
		<>
			<button
				className={classes.button}
				onClick={() => {
					setIsOpen(true)
				}}
				title="Search"
			>
				<svg
					viewBox="0 0 19 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classes.icon}
				>
					<path
						d="M8.0005 14.0005C8.78843 14.0005 9.56865 13.8453 10.2966 13.5438C11.0246 13.2423 11.686 12.8003 12.2431 12.2431C12.8003 11.686 13.2423 11.0246 13.5438 10.2966C13.8453 9.56865 14.0005 8.78843 14.0005 8.0005C14.0005 7.21257 13.8453 6.43236 13.5438 5.7044C13.2423 4.97645 12.8003 4.31501 12.2431 3.75786C11.686 3.20071 11.0246 2.75875 10.2966 2.45723C9.56865 2.1557 8.78843 2.0005 8.0005 2.0005C6.4092 2.0005 4.88308 2.63264 3.75786 3.75786C2.63264 4.88308 2.0005 6.4092 2.0005 8.0005C2.0005 9.5918 2.63264 11.1179 3.75786 12.2431C4.88308 13.3684 6.4092 14.0005 8.0005 14.0005ZM14.3205 12.9065L17.9005 16.4865C17.9959 16.5788 18.072 16.6892 18.1244 16.8113C18.1767 16.9333 18.2042 17.0645 18.2052 17.1973C18.2063 17.3301 18.1809 17.4618 18.1305 17.5846C18.0802 17.7075 18.0058 17.8191 17.9119 17.9129C17.8179 18.0067 17.7062 18.0809 17.5833 18.1311C17.4604 18.1813 17.3287 18.2065 17.1959 18.2052C17.0631 18.204 16.9319 18.1763 16.8099 18.1238C16.688 18.0713 16.5777 17.9951 16.4855 17.8995L12.9055 14.3195C11.298 15.5674 9.2753 16.1557 7.24926 15.9647C5.22322 15.7737 3.34611 14.8178 2.00005 13.2916C0.654 11.7653 -0.0598121 9.78345 0.00392902 7.74943C0.0676701 5.7154 0.904173 3.78213 2.34315 2.34315C3.78213 0.904173 5.7154 0.0676701 7.74943 0.00392902C9.78345 -0.0598121 11.7653 0.654 13.2916 2.00005C14.8178 3.34611 15.7737 5.22322 15.9647 7.24926C16.1557 9.2753 15.5674 11.298 14.3195 12.9055L14.3205 12.9065Z"
						fill="white"
					/>
				</svg>
			</button>

			<SearchPopUp isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	)
}

export default Search
