// CollisionDetection.js

export function checkCollision(movingSvgClassName) {
	const movingElements = document.querySelectorAll("." + movingSvgClassName)
	const collisionElements = document.querySelectorAll(".collision-element")

	const collidedElements = []

	movingElements.forEach(movingElement => {
		const movingRect = movingElement.getBoundingClientRect()

		collisionElements.forEach(collisionElement => {
			const collisionRect = collisionElement.getBoundingClientRect()

			const verticalMatch =
				movingRect.top <= collisionRect.bottom &&
				movingRect.bottom >= collisionRect.top

			const horizontalMatch =
				movingRect.left <= collisionRect.right &&
				movingRect.right >= collisionRect.left

			if (verticalMatch && horizontalMatch) {
				collidedElements.push(movingElement)
			}
		})
	})

	return collidedElements
}
