import React, { useState, Fragment, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./searchPopUp.module.css"
import { Dialog, Transition } from "@headlessui/react"
import DialogModule from "../dialogModule/DialogModule"
import SearchQuickLinks from "./QuickLinks"
import SearchItem from "./SearchItem"
import BirdIcon from "../birdIcon/BirdIcon"

const SearchPopUp = ({ isOpen, setIsOpen }) => {
	const classes = useStyle(defaultClasses)
	const data = useStaticQuery(graphql`
		query {
			allSanityPostPage {
				nodes {
					_type
					title
					seo {
						defaultMeta {
							title
							description
						}
					}
					slug {
						current
					}
					seo {
						defaultMeta {
							_key
							_rawImage
							image {
								asset {
									gatsbyImageData(
										placeholder: BLURRED
										width: 100
									)
								}
							}
						}
					}
				}
			}
			allSanityBasicContentPage {
				nodes {
					_type
					title
					seo {
						defaultMeta {
							title
							description
						}
					}
					slug {
						current
					}
					seo {
						defaultMeta {
							_key
							_rawImage
							image {
								asset {
									gatsbyImageData(
										placeholder: BLURRED
										width: 100
									)
								}
							}
						}
					}
				}
			}
			allSanityGeneralContentPage {
				nodes {
					_type
					title
					seo {
						defaultMeta {
							title
							description
						}
					}
					slug {
						current
					}
					seo {
						defaultMeta {
							_key
							_rawImage
							image {
								asset {
									gatsbyImageData(
										placeholder: BLURRED
										width: 100
									)
								}
							}
						}
					}
				}
			}
			allSanitySidebarContentPage {
				nodes {
					_type
					title
					seo {
						defaultMeta {
							title
							description
						}
					}
					slug {
						current
					}
					seo {
						defaultMeta {
							_key
							_rawImage
							image {
								asset {
									gatsbyImageData(
										placeholder: BLURRED
										width: 100
									)
								}
							}
						}
					}
				}
			}
		}
	`)

	const [query, setQuery] = useState(null)
	const [results, setResults] = useState([])

	const handleSearch = e => {
		const inputValue = e.target.value
		setQuery(inputValue)

		if (inputValue.trim() === "") {
			// If search query is empty, reset the results and display default quick links
			setResults(null)
			return
		}

		// Tokenize and normalize the search query
		const queryTokens = inputValue.toLowerCase().split(/\s+/)

		// Combine data from all data types
		const allNodes = [
			...data.allSanityPostPage.nodes,
			...data.allSanityBasicContentPage.nodes,
			...data.allSanityGeneralContentPage.nodes,
			...data.allSanitySidebarContentPage.nodes,
		]

		// Filter and rank the nodes based on the user's input
		const resultsWithScore = allNodes.map(node => {
			// Prepare content tokens for comparison
			const titleTokens = node?.title?.toLowerCase().split(/\s+/) || []
			const seoTitleTokens =
				node?.seo?.defaultMeta?.title?.toLowerCase().split(/\s+/) || []
			const descriptionTokens =
				node?.seo?.defaultMeta?.description
					?.toLowerCase()
					.split(/\s+/) || []

			// Calculate match score
			const score = queryTokens.reduce((acc, queryToken) => {
				const titleMatchCount = titleTokens.filter(token =>
					token.includes(queryToken)
				).length
				const seoTitleMatchCount = seoTitleTokens.filter(token =>
					token.includes(queryToken)
				).length
				const descriptionMatchCount = descriptionTokens.filter(token =>
					token.includes(queryToken)
				).length

				// Weight title matches higher than description matches
				return (
					acc +
					titleMatchCount * 2 +
					seoTitleMatchCount * 2 +
					descriptionMatchCount
				)
			}, 0)

			return { node, score }
		})

		// Filter out nodes with no matches and sort by score
		const sortedFilteredResults = resultsWithScore
			.filter(result => result.score > 0)
			.sort((a, b) => b.score - a.score)
			.map(result => result.node)

		// Limit the results to the first 5 items
		const limitedResults = sortedFilteredResults.slice(0, 6)

		setResults(limitedResults)
	}

	const [selectedResult, setSelectedResult] = useState(null)

	const handleKeyDown = e => {
		if (e.key === "ArrowDown") {
			e.preventDefault()
			setSelectedResult(prev =>
				prev === null || prev === results.length - 1 ? 0 : prev + 1
			)
		} else if (e.key === "ArrowUp") {
			e.preventDefault()
			setSelectedResult(prev =>
				prev === null || prev === 0 ? results.length - 1 : prev - 1
			)
		} else if (e.key === "Enter") {
			if (selectedResult !== null) {
				const selectedLink = results[selectedResult]
				const url =
					selectedLink?._type === "postPage"
						? `/news/${selectedLink?.slug?.current}`
						: `/${selectedLink?.slug?.current}`
				window.location.href = url // Navigate to the selected link
			}
		}
	}

	// Reset selected result when search query changes
	useEffect(() => {
		setSelectedResult(null)
	}, [query])

	// Reset query and results on open close
	useEffect(() => {
		if (isOpen === true) {
			setQuery(null)
			setResults([])
		}
	}, [isOpen])

	return (
		<>
			<DialogModule isOpen={isOpen} setIsOpen={setIsOpen}>
				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className={classes.container}>
								<div className={classes.wrapper}>
									<div className={classes.inner}>
										<input
											type="text"
											placeholder="Search..."
											value={query}
											onChange={handleSearch}
											onKeyDown={handleKeyDown} // Add keydown event listener
											className={classes.input}
										/>
										<div className={classes.returnedLinks}>
											{query === null || query === "" ? (
												<SearchQuickLinks />
											) : results.length > 0 ? (
												<>
													<h5
														className={
															classes.title
														}
													>
														Results
													</h5>
													<ul
														className={
															classes.unorderedList
														}
													>
														{results.map(
															(result, index) => (
																<li
																	key={index}
																	className={`${
																		classes.listItem
																	} ${
																		index ===
																		selectedResult
																			? classes.listItemSelected
																			: ""
																	}`}
																>
																	<SearchItem
																		result={
																			result
																		}
																	/>
																</li>
															)
														)}
													</ul>
												</>
											) : (
												// Display a message when no results are found after a search
												<div
													className={
														classes.noResultsMessage
													}
												>
													No results found. Try a
													different search.
												</div>
											)}
										</div>
									</div>
									<div className={classes.closeWrapper}>
										<button
											onClick={() => {
												setIsOpen(false)
											}}
											className={classes.closeButton}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												className={classes.closeIcon}
											>
												<path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z" />
											</svg>
											<span>close</span>
										</button>
									</div>
								</div>
								<div className={classes.birdWrapper}>
									<BirdIcon
										parentClasses={{
											bird: classes.bird,
										}}
									/>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</DialogModule>
		</>
	)
}

export default React.memo(SearchPopUp)
