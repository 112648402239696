import * as React from "react"
import Header from "../header/Header"
import Footer from "../footer/Footer"

const Layout = ({ children, location }) => {
	return (
		<>
			<Header location={location?.pathname} />
			{children}
			<Footer location={location?.pathname} />
		</>
	)
}

export default Layout
