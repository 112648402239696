// extracted by mini-css-extract-plugin
export var container = "footer-module--container--76df5 w-full bg-tertiary mb-0";
export var dcOne = "footer-module--dcOne--9d2ba basis-full lg_basis-auto font-medium text-white";
export var dcThree = "footer-module--dcThree--afe4a basis-full text-center lg_text-right mt-ms-2 pt-ms1 lg_pt-0 text-white/50 border-t-[1px] border-white/25 lg_border-0";
export var dcTwo = "footer-module--dcTwo--0618a basis-full lg_basis-auto lg_text-right text-white/50 mt-ms-2 lg_mt-0";
export var detailsContainer = "footer-module--detailsContainer--5f7a0 lg_border-t-[1px] border-white/25 text-xxs flex flex-wrap justify-between pb-ms4 lg_py-ms4";
export var disButton = "footer-module--disButton--4ad93 py-base flex gap-ms-6 justify-between items-center w-full bg-tertiary text-sm text-white font-light";
export var disContainer = "footer-module--disContainer--a71b1 w-full mb-ms2";
export var disPanel = "footer-module--disPanel--6aa6e bg-tertiary flex flex-col gap-ms-2 text-sm pb-base";
export var disclosure = "footer-module--disclosure--50d23 border-b-[1px] border-white/25";
export var logoWrapper = "footer-module--logoWrapper--60b0d w-ms16 block";
export var mainContainer = "footer-module--mainContainer--d3a29 lg_border-t-[1px] border-white/25 flex flex-col lg_flex-row text-white py-ms4";
export var mcContact = "footer-module--mcContact--d224e basis-1/5 text-xs text-xxs pr-ms8";
export var mcLogo = "footer-module--mcLogo--fb8cb flex justify-center lg_justify-start mb-ms2 lg_mb-0 lg_basis-1/4 lg_mr-ms12";
export var mcMenuOne = "footer-module--mcMenuOne--7cbb0 basis-1/5";
export var mcMenuTwo = "footer-module--mcMenuTwo--0a26d basis-1/3";
export var mcStrapline = "footer-module--mcStrapline--28574 hFour text-white";
export var mcTitle = "footer-module--mcTitle--c5b45 text-sm lg_mb-ms1";
export var navItem = "footer-module--navItem--06de8 text-xxs hover_text-primary transition ease-in-out duration-300 w-fit";
export var navItemLi = "footer-module--navItemLi--3f610 contents";
export var navItemUl = "footer-module--navItemUl--20cf3 flex flex-col gap-ms-8";
export var tel = "footer-module--tel--4886f mt-ms2 lg_my-ms2";
export var wrapper = "footer-module--wrapper--801f2 max-w-screen-3xl mx-auto w-full py-ms3 lg_py-ms6 px-ms1 lg_px-ms3";