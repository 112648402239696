import React, { useEffect, useState, useMemo } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./footer.module.css"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { Disclosure } from "@headlessui/react"
import SocailLinks from "../socialLinks/SocialLinks"
import LogoLinks from "./LogoLinks"

const Footer = ({ location }) => {
	const classes = useStyle(defaultClasses)

	const data = useStaticQuery(graphql`
		query footer {
			sanitySiteSettings {
				title
				phoneNumber
				streetAddress
				addressLocality
				addressRegion
				postalCode
				businessNumber
				charityNumber
			}
			sanityFooterSettings {
				menuOneTitle
				menuOne {
					title
					link {
						__typename
						... on SanityBasicContentPage {
							slug {
								current
							}
						}
						... on SanityGeneralContentPage {
							slug {
								current
							}
						}
						... on SanityPostPage {
							slug {
								current
							}
						}
						... on SanitySidebarContentPage {
							slug {
								current
							}
						}
						... on SanityFileReference {
							pdfFile {
								asset {
									url
								}
							}
						}
					}
				}
				menuTwoTitle
				menuTwo {
					title
					link {
						__typename
						... on SanityBasicContentPage {
							slug {
								current
							}
						}
						... on SanityGeneralContentPage {
							slug {
								current
							}
						}
						... on SanityPostPage {
							slug {
								current
							}
						}
						... on SanitySidebarContentPage {
							slug {
								current
							}
						}
						... on SanityFileReference {
							pdfFile {
								asset {
									url
								}
							}
						}
					}
				}
				logoLinks {
					link
					image {
						_rawDesktopImage
						_rawMobileImage
						desktopImage {
							asset {
								gatsbyImageData(
									placeholder: BLURRED
									width: 640
								)
							}
						}
						mobileImage {
							asset {
								gatsbyImageData(
									placeholder: BLURRED
									width: 420
								)
							}
						}
					}
				}
			}
		}
	`)

	let date = new Date().getFullYear()

	//get window dimensions
	const { width } = useWindowDimensions()

	const [isMobileView, setIsMobileView] = useState(false)

	// Check the window width on mount and whenever it changes due to orientation change
	useEffect(() => {
		if (width <= 1024) {
			setIsMobileView(true)
		} else {
			setIsMobileView(false)
		}
	}, [width])

	const items = [
		{
			title: data?.sanityFooterSettings?.menuOneTitle,
			links: data?.sanityFooterSettings?.menuOne,
		},
		{
			title: data?.sanityFooterSettings?.menuTwoTitle,
			links: data?.sanityFooterSettings?.menuTwo,
		},
		{
			title: "Contact us",
			address: `${data?.sanitySiteSettings?.streetAddress}, ${data?.sanitySiteSettings?.addressLocality}, ${data?.sanitySiteSettings?.addressRegion}, ${data?.sanitySiteSettings?.postalCode}`,
			phone: data?.sanitySiteSettings?.phoneNumber,
		},
	]

	// Render anchor links for desktop
	const renderLinks = () => (
		<>
			<div className={classes.mcMenuOne}>
				<div className={classes.mcTitle}>{items[0]?.title}</div>
				<ul className={classes.navItemUl}>
					{items[0]?.links?.map((link, index) => {
						return (
							<li key={index} className={classes.navItemLi}>
								{link?.link?.__typename ===
								"SanityFileReference" ? (
									// For PDF files - checking if the URL exists to avoid broken links
									<a
										href={link?.link?.pdfFile?.asset?.url}
										title={link?.title}
										className={classes.navItem}
										target="_blank"
									>
										{link?.title}
									</a>
								) : (
									// For internal navigation links - assuming 'slug' might be undefined at times
									<Link
										to={`/${link?.link?.slug?.current}`}
										className={classes.navItem}
										title={link?.title}
									>
										{link?.title}
									</Link>
								)}
							</li>
						)
					})}
				</ul>
			</div>
			<div className={classes.mcMenuTwo}>
				<div className={classes.mcTitle}>{items[1]?.title}</div>
				<ul className={classes.navItemUl}>
					{items[1]?.links?.map((link, index) => {
						return (
							<li key={index} className={classes.navItemLi}>
								{link?.link?.__typename ===
								"SanityFileReference" ? (
									// For PDF files - checking if the URL exists to avoid broken links
									<a
										href={link?.link?.pdfFile?.asset?.url}
										title={link?.title}
										className={classes.navItem}
										target="_blank"
									>
										{link?.title}
									</a>
								) : (
									// For internal navigation links - assuming 'slug' might be undefined at times
									<Link
										to={`/${link?.link?.slug?.current}`}
										className={classes.navItem}
										title={link?.title}
									>
										{link?.title}
									</Link>
								)}
							</li>
						)
					})}
				</ul>
			</div>
			<div className={classes.mcContact}>
				<div className={classes.mcTitle}>Contact Us</div>
				{data?.sanitySiteSettings?.title}
				<br />
				{data?.sanitySiteSettings?.streetAddress},<br />
				{data?.sanitySiteSettings?.addressLocality},<br />
				{data?.sanitySiteSettings?.addressRegion},<br />
				<a
					href={`https://www.google.com/maps?q=${data?.sanitySiteSettings?.postalCode}`}
					className={classes.navItem}
					title={`View ${data?.sanitySiteSettings?.postalCode} on Google Maps`}
					target="_blank"
				>
					{data?.sanitySiteSettings?.postalCode}
				</a>
				<div className={classes.tel}>
					<a
						href={"tel:" + data?.sanitySiteSettings?.phoneNumber}
						title="Call us"
						className={classes.navItem}
					>
						Tel:{" "}
						<strong>{data?.sanitySiteSettings?.phoneNumber}</strong>
					</a>
				</div>
				<SocailLinks showHandles={true} />
			</div>
		</>
	)

	const refs = useMemo(() => {
		return (
			items?.map(() => {
				return React.createRef()
			}) ?? []
		)
	}, [items])

	function handleClosingOthers(id) {
		const otherRefs = refs.filter(ref => {
			return ref.current?.getAttribute("data-id") !== id
		})

		otherRefs.forEach(ref => {
			const isOpen = ref.current?.getAttribute("data-open") === "true"

			if (isOpen) {
				ref.current?.click()
			}
		})
	}

	const renderDisclosure = () => (
		<>
			<div className={classes.disContainer}>
				{items?.map((item, idx) => (
					<Disclosure
						as="div"
						className={classes.disclosure}
						key={idx}
					>
						{({ open }) => (
							<>
								<Disclosure.Button
									className={classes.disButton}
									ref={refs[idx]}
									data-id={idx}
									data-open={open}
									onClick={() =>
										handleClosingOthers(String(idx))
									}
								>
									<div className={classes.mcTitle}>
										{item.title}
									</div>
									<ChevronUpIcon
										className={`${
											open
												? "rotate-180 transition duration-300 ease-in-out"
												: "transition duration-300 ease-in-out"
										} h-ms1 w-ms1`}
									/>
								</Disclosure.Button>
								<Disclosure.Panel className={classes.disPanel}>
									{item.links ? (
										<ul className={classes.navItemUl}>
											{item.links.map((link, index) => (
												<li
													key={index}
													className={
														classes.navItemLi
													}
												>
													{link?.link?.__typename ===
													"SanityFileReference" ? (
														// For PDF files - checking if the URL exists to avoid broken links
														<a
															href={
																link?.link
																	?.pdfFile
																	?.asset?.url
															}
															title={link?.title}
															className={
																classes.navItem
															}
															target="_blank"
														>
															{link?.title}
														</a>
													) : (
														// For internal navigation links - assuming 'slug' might be undefined at times
														<Link
															to={`/${link?.link?.slug?.current}`}
															className={
																classes.navItem
															}
															title={link?.title}
														>
															{link?.title}
														</Link>
													)}
												</li>
											))}
										</ul>
									) : (
										<div className={classes.mcContact}>
											{item?.address}
											<br />
											<div className={classes.tel}>
												<a
													href={"tel:" + item?.phone}
													title="Call us"
												>
													Tel:{" "}
													<strong>
														{item?.phone}
													</strong>
												</a>
											</div>
										</div>
									)}
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				))}
				<div className="mt-ms2 flex w-full">
					<SocailLinks showHandles={false} />
				</div>
			</div>
		</>
	)

	return (
		<>
			<div
				className={`componentSpacing collision-element ${classes.container}`}
			>
				<div className={classes.wrapper}>
					<LogoLinks items={data?.sanityFooterSettings?.logoLinks} />
					<div className={classes.mainContainer}>
						<div className={classes.mcLogo}>
							<div className={classes.mcStrapline}>
								Instilling values, inspiring minds.
							</div>
						</div>
						{isMobileView ? renderDisclosure() : renderLinks()}
					</div>

					<div className={classes.detailsContainer}>
						<div className={classes.dcOne}>
							&copy; {data?.sanitySiteSettings?.title} {date}
						</div>
						<div className={classes.dcTwo}>
							Registered in England: Number{" "}
							{data?.sanitySiteSettings?.businessNumber}.
							Registered Charity Number:{" "}
							{data?.sanitySiteSettings?.charityNumber}.
							Registered office:{" "}
							{data?.sanitySiteSettings?.streetAddress},{" "}
							{data?.sanitySiteSettings?.addressLocality},{" "}
							{data?.sanitySiteSettings?.addressRegion},{" "}
							{data?.sanitySiteSettings?.postalCode}
						</div>
						<div className={classes.dcThree}>
							<a
								href="https://www.ecrubox.com/"
								title="Web design by Ecrubox"
							>
								WEB DESIGN BY ECRUBOX
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer

const ChevronUpIcon = ({ className }) => {
	return (
		<svg
			width="24"
			height="12"
			viewBox="0 0 24 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M22.9734 1L11.9867 11.088L1 1"
				stroke="white"
				strokeWidth="0.776001"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
