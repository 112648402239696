import React, {
	Fragment,
	useState,
	useEffect,
	useRef,
	useLayoutEffect,
} from "react"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./header.module.css"
import { Transition } from "@headlessui/react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Logo from "../logo/Logo"
import BurgerMenu from "../burgerMenu/BurgerMenu"
import Menu from "../menu/Menu"
import Search from "../search/Search"
import StickyMessage from "../stickyMessage/StickyMessage"
import AnnouncementBar from "../announcementBar/AnnouncementBar"

const Header = ({ location }) => {
	const classes = useStyle(defaultClasses)

	gsap.registerPlugin(ScrollTrigger)

	// header styling state
	const [solidHeader, setSolidHeader] = useState(false)

	// show menu state
	const [show, setShow] = useState(false)

	// opne and close menu functions
	function openMenu() {
		setShow(true)
	}

	function closeMenu() {
		setShow(false)
	}

	//find news urls
	var newsRegex = /\/news\//

	// close menu on location change
	useEffect(() => {
		setShow(false)
		// set state if the page is a news page
		setSolidHeader(newsRegex.test(location))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	//add bg color on scroll
	const headerRef = useRef(null)
	useLayoutEffect(() => {
		if (!solidHeader) {
			let ctx = gsap.context(() => {
				gsap.timeline({
					scrollTrigger: {
						start: "top -70",
						end: 99999,
						toggleClass: {
							className: "bg-tertiary",
							targets: headerRef.current,
						},
						id: "header",
					},
				})
			}, headerRef) // <- IMPORTANT! Scopes selector text

			return () => ctx.revert() // cleanup
		}
	}, [solidHeader]) // <- empty dependency Array so it doesn't re-run on every render

	return (
		<>
			<AnnouncementBar />
			<div className="relative">
				<div className={classes.gradient} />
				<div
					className={`${classes.container} ${
						solidHeader ? classes.blockContainer : ""
					}`}
					ref={headerRef}
				>
					<div className={classes.wrapper}>
						<Logo
							parentClasses={{
								logoWrapper: classes.logoWrapper,
							}}
						/>
						<div className={classes.interactionsWrapper}>
							<Search />
							<BurgerMenu showMenu={openMenu} />
						</div>
					</div>
					<Transition.Root show={show}>
						<BackgroundLayer />
						<SlideOverLayer>
							<Menu showMenu={closeMenu} />
						</SlideOverLayer>
					</Transition.Root>
				</div>
				<StickyMessage
					parentClasses={{
						arrow: show ? "fill-white" : "fill-tertiary",
					}}
				/>
			</div>
		</>
	)
}

export default Header

const BackgroundLayer = () => (
	<Transition.Child
		enter="transition-opacity ease-in-out duration-500"
		enterFrom="opacity-0"
		enterTo="opacity-100"
		leave="transition-opacity ease-in-out duration-500"
		leaveFrom="opacity-100"
		leaveTo="opacity-0"
	>
		<div className="fixed inset-0 bg-gray-500 opacity-75" />
	</Transition.Child>
)

const SlideOverLayer = ({ children }) => (
	<Transition.Child
		as={Fragment}
		enter="transform transition ease-in-out duration-500"
		enterFrom="-translate-x-full"
		enterTo="translate-x-0"
		leave="transform transition ease-in-out duration-500 delay-100"
		leaveFrom="translate-x-0"
		leaveTo="-translate-x-full"
	>
		<div className="fixed inset-0 overflow-hidden z-50">
			<div className="absolute inset-0 overflow-hidden">
				<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
					<div className="pointer-events-auto w-screen">
						<div className="flex h-full flex-col overflow-y-auto bg-tertiary shadow-xl">
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	</Transition.Child>
)
