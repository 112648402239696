import React from "react"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./responsiveImages.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

const ResponsiveImage = ({ images, parentClasses }) => {
	const classes = useStyle(defaultClasses, parentClasses)
	const { width } = useWindowDimensions()

	const { sanityStudioProjectID, sanityStudioDataset } = useSiteMetadata()

	if (images?.desktopImage?.asset?.gatsbyImageData) {
		if (width < 640 && images?.mobileImage?.asset?.gatsbyImageData) {
			return (
				<GatsbyImage
					image={images?.mobileImage?.asset?.gatsbyImageData}
					alt={
						images?._rawDesktopImage?.alt
							? images?._rawDesktopImage?.alt
							: "image"
					}
					className={classes.image}
				/>
			)
		} else {
			return (
				<GatsbyImage
					image={images?.desktopImage?.asset?.gatsbyImageData}
					alt={
						images?._rawDesktopImage?.alt
							? images?._rawDesktopImage?.alt
							: "image"
					}
					className={classes.image}
				/>
			)
		}
	} else {
		const sanityConfig = {
			projectId: sanityStudioProjectID,
			dataset: sanityStudioDataset,
		}

		const desktopImageAssetId = images?.desktopImage?.asset?.id

		const desktopImageData = getGatsbyImageData(
			desktopImageAssetId,
			{ width: 1280 },
			sanityConfig
		)

		const mobileImageAssetId = images?.mobileImage?.asset?.id

		const mobileImageData = getGatsbyImageData(
			mobileImageAssetId,
			{ width: 1280 },
			sanityConfig
		)

		if (width < 640 && mobileImageAssetId) {
			return (
				<GatsbyImage
					image={mobileImageData}
					alt={
						images?._rawDesktopImage?.alt
							? images?._rawDesktopImage?.alt
							: "image"
					}
					className={classes.image}
				/>
			)
		} else {
			return (
				<GatsbyImage
					image={desktopImageData}
					alt={
						images?._rawDesktopImage?.alt
							? images?._rawDesktopImage?.alt
							: "image"
					}
					className={classes.image}
				/>
			)
		}
	}
}

export default ResponsiveImage
