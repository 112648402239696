// extracted by mini-css-extract-plugin
export var activeImage = "menu-module--activeImage--0d100 opacity-100";
export var activeLink = "menu-module--activeLink--efb69 !pl-1 !text-primary pointer-events-none";
export var bird = "menu-module--bird--e1e37 size-full fill-white opacity-10";
export var birdWrapper = "menu-module--birdWrapper--b1d60 absolute top-0 size-full overflow-clip";
export var bottomLink = "menu-module--bottomLink--bd26b text-white text-sm lg_text-base font-serif hover_pl-1 hover_text-primary transition-all duration-300 ease-in-out";
export var bottomLinks = "menu-module--bottomLinks--87ef9 flex flex-col gap-0";
export var bottomSection = "menu-module--bottomSection--f5872 flex flex-col gap-ms2 flex flex-col justify-center items-start";
export var closeButton = "menu-module--closeButton--0cf87 absolute top-ms1 lg_top-ms3 right-ms1 lg_right-ms3 text-white flex gap-ms-6 items-center justify-center z-20";
export var closeButtonMobile = "menu-module--closeButtonMobile--5b10e absolute top-ms1 right-ms2 lg_hidden";
export var closeIcon = "menu-module--closeIcon--92b55 fill-white w-ms1 h-ms1";
export var container = "menu-module--container--036d9 size-full";
export var image = "menu-module--image--b434b !absolute opacity-0 object-cover size-full z-10 transition ease-in-out duration-300";
export var imageContainer = "menu-module--imageContainer--18c1e hidden xl_block basis-6/12 2xl_basis-7/12 h-full relative";
export var linksContainer = "menu-module--linksContainer--32226 basis-full xl_basis-6/12 2xl_basis-5/12 h-full py-ms1 lg_py-ms3 px-ms1 lg_px-ms3 flex flex-col justify-between";
export var logoWrapper = "menu-module--logoWrapper--d7f91 w-ms12 block";
export var topLink = "menu-module--topLink--06578 text-lg md_text-2xl text-white font-serif hover_pl-1 hover_text-primary transition-all duration-300 ease-in-out";
export var topLinks = "menu-module--topLinks--4e8ed mt-ms10 lg_mt-ms8 flex flex-col gap-ms-2 lg_gap-ms1";
export var topSection = "menu-module--topSection--7e486 flex flex-col justify-center items-start";
export var wrapper = "menu-module--wrapper--280e2 max-w-screen-3xl mx-auto size-full flex";