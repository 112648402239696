import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./searchPopUp.module.css"
import SearchItem from "./SearchItem"

const SearchQuickLinks = () => {
	const classes = useStyle(defaultClasses)
	const data = useStaticQuery(graphql`
		query {
			sanitySearchSettings {
				quickLinks {
					... on SanityBasicContentPage {
						id
						_type
						title
						slug {
							current
						}
						seo {
							defaultMeta {
								_key
								_rawImage
								image {
									asset {
										gatsbyImageData(
											placeholder: BLURRED
											width: 100
										)
									}
								}
							}
						}
					}
					... on SanityGeneralContentPage {
						id
						_type
						title
						slug {
							current
						}
						seo {
							defaultMeta {
								_key
								_rawImage
								image {
									asset {
										gatsbyImageData(
											placeholder: BLURRED
											width: 100
										)
									}
								}
							}
						}
					}
					... on SanityPostPage {
						id
						title
						_type
						slug {
							current
						}
						seo {
							defaultMeta {
								_key
								_rawImage
								image {
									asset {
										gatsbyImageData(
											placeholder: BLURRED
											width: 100
										)
									}
								}
							}
						}
					}
					... on SanitySidebarContentPage {
						id
						_type
						title
						slug {
							current
						}
						seo {
							defaultMeta {
								_key
								_rawImage
								image {
									asset {
										gatsbyImageData(
											placeholder: BLURRED
											width: 100
										)
									}
								}
							}
						}
					}
				}
			}
			sanityPostPageSettings {
				title
				seo {
					defaultMeta {
						_key
						_rawImage
						image {
							asset {
								gatsbyImageData(
									placeholder: BLURRED
									width: 100
								)
							}
						}
					}
				}
			}
		}
	`)

	const results = data?.sanitySearchSettings?.quickLinks

	const newsLink = data?.sanityPostPageSettings

	// Adding slug.current to the newsLink object
	newsLink.slug = { current: "news" }

	return (
		<>
			<h5 className={classes.title}>Quick Links</h5>
			<ul className={classes.unorderedList}>
				{results?.map((result, index) => (
					<li key={index} className={classes.listItem}>
						<SearchItem result={result} />
					</li>
				))}
				<li className={classes.listItem}>
					<SearchItem result={newsLink} />
				</li>
			</ul>
		</>
	)
}

export default SearchQuickLinks
