import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
					image
					type
					sanityStudioProjectID
					sanityStudioDataset
					googleMapsApi
				}
			}
		}
	`)

	return data.site.siteMetadata
}
