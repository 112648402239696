import React, { createContext, useState } from "react"

export const LayoutContext = createContext()

const LayoutProvider = ({ children }) => {
	const [layout, setLayout] = useState({
		header: false,
	})

	return (
		<LayoutContext.Provider value={{ layout, setLayout }}>
			{children}
		</LayoutContext.Provider>
	)
}

export default LayoutProvider
