import React from "react"

const BurgerMenu = ({ showMenu }) => {
	return (
		<>
			<button onClick={() => showMenu()} className="relative group">
				<div className="flex flex-col gap-ms-6 justify-between transform transition-all duration-300 origin-center overflow-hidden">
					<div className="bg-white ml-auto h-[2px] w-ms4 transform transition-all duration-300 origin-left group-focus_translate-x-10"></div>
					<div className="bg-white ml-auto h-[2px] w-ms2 rounded transform transition-all duration-300 group-focus_translate-x-10 delay-75"></div>
					<div className="bg-white ml-auto h-[2px] w-base transform transition-all duration-300 origin-left group-focus_translate-x-10 delay-150"></div>
				</div>
			</button>
		</>
	)
}

export default BurgerMenu
