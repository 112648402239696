import React from "react"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./birdIcon.module.css"

const BirdIcon = ({ parentClasses }) => {
	const classes = useStyle(defaultClasses, parentClasses)
	return (
		<svg
			viewBox="0 0 1066 889"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classes.bird}
		>
			<g clipPath="url(#clip0_881_4714)">
				<path d="M619.241 410.822C620.538 407.581 621.186 403.692 621.835 400.451C639.342 421.193 656.849 445.175 671.763 472.399C671.763 472.399 739.847 530.086 936.318 484.066C936.318 484.066 846.187 538.512 752.815 546.291C698.348 525.549 639.99 493.788 577.742 447.768C594.601 442.583 612.757 432.212 619.889 410.822" />
				<path d="M926.589 618.887C857.856 629.906 778.749 638.332 696.4 637.684C526.514 636.388 396.182 550.181 333.934 498.975C245.101 426.379 213.328 291.559 235.375 258.502C236.671 257.205 238.617 253.965 243.156 253.316H245.101C249.64 253.316 256.124 254.613 265.85 260.446C276.873 266.928 302.81 294.8 325.505 319.43C353.387 349.246 381.917 380.359 403.963 397.211C444.814 428.324 482.422 445.824 520.679 450.362C584.224 502.216 724.282 614.35 927.237 618.887" />
				<path d="M322.912 666.203C325.506 672.685 328.748 683.055 336.529 696.667C307.35 696.667 258.071 685.648 247.696 619.534C248.344 620.182 278.172 626.664 304.108 610.46C296.327 605.274 282.71 596.2 241.86 559.254C191.932 515.178 159.511 473.047 141.355 441.286C114.122 393.321 106.989 350.541 103.747 325.911C95.9659 271.464 97.9112 201.461 96.6143 187.201C94.0207 161.274 90.7786 138.588 86.2397 122.384C82.9976 112.013 79.7555 103.587 72.6229 99.0494C49.9282 83.4932 7.1326 87.3823 1.94526 86.7341H0C7.1326 84.7896 12.32 83.4932 17.5073 80.9005C27.2336 76.3633 35.0146 69.8816 42.7956 65.3443C58.3577 56.2699 71.326 49.7881 81.7007 50.4363C81.7007 50.4363 105.692 13.4903 151.081 -0.769531C133.574 11.5458 120.606 28.3984 113.473 48.4918C104.395 74.4188 111.528 101.642 132.926 119.143C151.73 134.699 181.557 143.774 217.869 152.848C254.828 162.571 269.094 185.257 267.797 198.22C266.5 209.887 252.883 213.776 243.805 215.073C228.243 215.073 214.627 222.851 204.9 237.111C186.745 264.334 187.393 314.892 207.494 373.228C228.243 434.156 265.852 489.899 310.592 526.845C340.42 551.476 385.161 583.236 441.573 611.108C434.44 625.368 421.472 646.758 401.371 653.887C386.457 659.073 367.005 657.776 349.498 646.109C331.99 634.442 307.999 616.941 307.999 616.941C307.999 616.941 318.373 654.536 322.912 664.906" />
				<path d="M422.12 815.284C394.238 818.525 381.269 817.229 368.949 819.821C368.949 819.821 379.972 809.451 379.972 782.227C379.972 772.505 378.675 760.189 375.433 745.281C394.886 760.837 416.284 769.264 439.627 769.264C438.33 780.931 438.978 795.191 442.869 810.747C437.033 812.043 430.549 813.34 422.12 814.636" />
				<path d="M638.692 836.025C613.403 828.895 586.818 816.58 562.178 739.447C562.178 739.447 564.124 777.041 552.452 804.264C542.077 827.599 530.406 832.136 514.195 854.822C461.025 810.098 480.478 756.948 481.126 754.355L494.094 721.298C494.094 721.298 479.829 727.131 467.509 730.372C459.728 732.317 451.947 732.965 446.111 732.965C416.284 732.965 392.941 713.52 379.324 694.075C391.644 695.371 403.316 694.075 414.339 689.537C446.76 677.87 466.212 646.758 475.939 627.312C506.414 640.276 538.835 651.295 574.498 659.721C578.389 673.333 585.521 694.723 598.49 716.112C625.723 760.837 665.277 786.115 713.26 790.004C704.182 812.691 684.081 847.692 639.34 835.377" />
				<path d="M651.011 725.188C632.855 707.687 621.832 685.649 615.348 668.797C631.558 671.39 647.769 673.334 664.627 673.982C675.002 680.464 698.345 695.372 725.579 717.41C710.665 720.651 687.322 723.892 651.659 725.188" />
				<path d="M882.496 761.485C882.496 761.485 878.605 768.615 877.957 768.615C877.957 768.615 877.308 769.263 876.66 769.911L867.582 774.448C867.582 774.448 898.706 821.765 882.496 887.879C872.121 832.136 840.348 776.393 788.475 723.891C769.022 703.797 748.921 686.945 731.414 673.981C753.46 673.981 775.507 672.685 796.904 670.74C828.028 696.019 903.893 742.039 1066 769.911C1066 769.911 983.649 786.764 882.496 760.837" />
				<path d="M533.001 304.521C507.064 279.89 481.776 257.204 458.433 239.055C407.856 199.516 369.6 178.775 369.6 178.775C369.6 178.775 367.006 131.458 336.53 80.252C315.133 44.6023 280.766 21.268 243.807 14.1381C227.596 12.1936 210.089 14.1381 194.527 19.3235C178.316 24.5089 157.567 36.8242 148.489 61.4549C144.598 72.4739 147.192 82.8447 156.27 90.6228C169.238 101.642 197.12 109.42 226.948 117.198C250.939 123.68 271.04 135.347 285.305 150.903C299.571 166.459 306.055 185.256 304.109 202.757C302.813 213.128 298.922 223.499 290.493 232.573C305.406 244.24 327.452 267.575 352.092 294.15C377.381 321.373 406.56 352.486 426.012 367.394C485.667 412.766 525.869 416.007 550.508 414.062C563.477 413.414 579.687 410.173 583.578 398.506C588.765 382.302 574.5 347.3 532.353 304.521H533.001ZM205.55 87.3819C193.23 87.3819 183.504 77.6593 183.504 65.3439C183.504 53.0286 193.23 43.306 205.55 43.306C217.87 43.306 227.596 53.0286 227.596 65.3439C227.596 77.6593 217.87 87.3819 205.55 87.3819Z" />
			</g>
			<defs>
				<clipPath id="clip0_881_4714">
					<rect
						width="1066"
						height="888"
						fill="white"
						transform="translate(0 0.527344)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default BirdIcon
