import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./menu.module.css"
import SocailLinks from "../socialLinks/SocialLinks"
import Logo from "../logo/Logo"
import BirdIcon from "../birdIcon/BirdIcon"

import { useLocation } from "@reach/router"

const Menu = ({ showMenu }) => {
	const classes = useStyle(defaultClasses)
	const location = useLocation()

	const data = useStaticQuery(graphql`
		query headerMenu {
			sanityHeaderSettings {
				mainMenu {
					title
					link {
						... on SanityBasicContentPage {
							slug {
								current
							}
							seo {
								defaultMeta {
									_key
									_rawImage
									image {
										asset {
											gatsbyImageData(
												placeholder: BLURRED
												width: 1280
											)
										}
									}
								}
							}
						}
						... on SanityGeneralContentPage {
							slug {
								current
							}
							seo {
								defaultMeta {
									_key
									_rawImage
									image {
										asset {
											gatsbyImageData(
												placeholder: BLURRED
												width: 1280
											)
										}
									}
								}
							}
						}
						... on SanityPostPage {
							slug {
								current
							}
							seo {
								defaultMeta {
									_key
									_rawImage
									image {
										asset {
											gatsbyImageData(
												placeholder: BLURRED
												width: 1280
											)
										}
									}
								}
							}
						}
						... on SanitySidebarContentPage {
							slug {
								current
							}
							seo {
								defaultMeta {
									_key
									_rawImage
									image {
										asset {
											gatsbyImageData(
												placeholder: BLURRED
												width: 1280
											)
										}
									}
								}
							}
						}
					}
				}
				secondaryMenu {
					title
					link {
						... on SanityBasicContentPage {
							slug {
								current
							}
						}
						... on SanityGeneralContentPage {
							slug {
								current
							}
						}
						... on SanityPostPage {
							slug {
								current
							}
						}
						... on SanitySidebarContentPage {
							slug {
								current
							}
						}
					}
				}
			}
		}
	`)

	const [hoveredImage, setHoveredImage] = useState()

	return (
		<>
			<div className={`${classes.container}`}>
				<div className={classes.wrapper}>
					<div className={classes.linksContainer}>
						<div className={classes.topSection}>
							<Logo
								parentClasses={{
									logoWrapper: classes.logoWrapper,
								}}
							/>
							<button
								onClick={() => showMenu()}
								className={classes.closeButtonMobile}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									className={classes.closeIcon}
								>
									<path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z" />
								</svg>
							</button>
							<ul className={classes.topLinks}>
								{data?.sanityHeaderSettings?.mainMenu.map(
									(link, index) => {
										const imageData = getImage(
											link?.link?.seo?.defaultMeta?.image
												?.asset?.gatsbyImageData
										)
										const isActive =
											location.pathname ===
											"/" +
												link?.link?.slug?.current +
												"/"

										return (
											<li key={index}>
												<Link
													to={
														"/" +
														link?.link?.slug
															?.current +
														"/"
													}
													className={`${
														isActive
															? classes.activeLink
															: ""
													} ${classes.topLink} `}
													title={link.title}
													onMouseEnter={() =>
														setHoveredImage(
															imageData
														)
													}
													onMouseLeave={() =>
														setHoveredImage()
													}
												>
													{link.title}
												</Link>
											</li>
										)
									}
								)}
								<li>
									<Link
										className={`${
											location.pathname === "/news/"
												? classes.activeLink
												: ""
										} ${classes.topLink}`}
										to="/news/"
									>
										News
									</Link>
								</li>
							</ul>
						</div>

						<div className={classes.bottomSection}>
							<ul className={classes.bottomLinks}>
								{data?.sanityHeaderSettings?.secondaryMenu.map(
									(link, index) => {
										return (
											<li key={index}>
												<Link
													to={
														"/" +
														link?.link?.slug
															?.current
													}
													className={
														classes.bottomLink
													}
													title={link.title}
												>
													{link.title}
												</Link>
											</li>
										)
									}
								)}
							</ul>
							<SocailLinks />
						</div>
					</div>

					<div className={classes.imageContainer}>
						{data?.sanityHeaderSettings?.mainMenu.map(
							(link, index) => {
								const imageData = getImage(
									link?.link?.seo?.defaultMeta?.image?.asset
										?.gatsbyImageData
								)
								return (
									<GatsbyImage
										key={index}
										image={imageData}
										className={`${classes.image} ${
											hoveredImage === imageData
												? classes.activeImage
												: ""
										}`}
									/>
								)
							}
						)}
						<button
							onClick={() => showMenu()}
							className={classes.closeButton}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								className={classes.closeIcon}
							>
								<path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z" />
							</svg>
							close
						</button>
						<div className={classes.birdWrapper}>
							<BirdIcon
								parentClasses={{
									bird: classes.bird,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Menu
