// extracted by mini-css-extract-plugin
export var button = "rta-module--button--6d621 relative";
export var container = "rta-module--container--6ec0f w-full break-words [&>*>p]_mt-ms2 md_mt-ms3 first_mt-0 [&>*>ul]_list-disc [&>*>ul]_list-outside [&>*>ul]_ms-8 [&>*>ul]_pt-ms2";
export var firstRowCell = "rta-module--firstRowCell--ad424 font-bold p-ms1";
export var firstRowStyle = "rta-module--firstRowStyle--d40c0 bg-tertiary text-white";
export var otherRowCell = "rta-module--otherRowCell--16ca3 p-ms1";
export var otherRowsStyle = "rta-module--otherRowsStyle--b71c8 align-top";
export var padding = "rta-module--padding--ec07f [&>*>p]_px-ms4";
export var readMore = "rta-module--readMore--8a4b9 mt-ms1";
export var tableStyles = "rta-module--tableStyles--8944a table-fixed w-full break-words mt-ms4";
export var wrapper = "rta-module--wrapper--807ed max-w-screen-md mx-auto w-full";