exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/BasicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-category-template-js": () => import("./../../../src/templates/PostCategoryTemplate.js" /* webpackChunkName: "component---src-templates-post-category-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-posts-template-js": () => import("./../../../src/templates/PostsTemplate.js" /* webpackChunkName: "component---src-templates-posts-template-js" */)
}

