import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./logo.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

const Logo = ({ parentClasses }) => {
	const classes = useStyle(defaultClasses, parentClasses)

	const data = useStaticQuery(graphql`
		query siteLogo {
			sanitySiteSettings {
				title
				siteLogo {
					asset {
						gatsbyImageData(placeholder: NONE, width: 640)
					}
				}
			}
		}
	`)

	return (
		<Link to="/" className={classes.logoWrapper}>
			<GatsbyImage
				image={
					data?.sanitySiteSettings?.siteLogo?.asset?.gatsbyImageData
				}
				alt={data?.sanitySiteSettings?.title}
				className={classes.logo}
			/>
		</Link>
	)
}

export default Logo
