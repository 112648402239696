import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./socialLinks.module.css"
import LinkedinIcon from "./LinkedinIcon"
import InstagramIcon from "./InstagramIcon"
import FacebookIcon from "./FacebookIcon"
import TwitterIcon from "./TwitterIcon"

const SocialLinks = ({ showHandles, parentClasses }) => {
	const classes = useStyle(defaultClasses, parentClasses)

	const data = useStaticQuery(graphql`
		query socialLinks {
			sanitySiteSettings {
				socialChannels {
					platform
					address
				}
				customSocialChannels
			}
		}
	`)

	return (
		<>
			<div className={classes.container}>
				{data?.sanitySiteSettings?.socialChannels.map((link, index) => {
					const { platform, address: socialLink } = link
					let IconComponent

					switch (platform) {
						case "LinkedIn":
							IconComponent = LinkedinIcon
							break
						case "Instagram":
							IconComponent = InstagramIcon
							break
						case "Facebook":
							IconComponent = FacebookIcon
							break
						case "Twitter":
							IconComponent = TwitterIcon
							break
						default:
							IconComponent = null
					}

					if (!IconComponent) return null

					return (
						<a
							key={index}
							href={socialLink}
							rel="noreferrer"
							title={platform}
							target="_blank"
							className={classes.link}
						>
							<IconComponent
								className={` ${classes.socialIcon} ${classes.socialIconColor} ${classes.socialIconSize}`}
							/>
						</a>
					)
				})}
			</div>
			{showHandles && (
				<div className={classes.handlesContainer}>
					{data?.sanitySiteSettings?.customSocialChannels?.map(
						(link, index) => (
							<div key={index} className={classes.handlesTitle}>
								{link}
							</div>
						)
					)}
				</div>
			)}
		</>
	)
}

export default SocialLinks
