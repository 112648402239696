import React, { useState } from "react"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./rta.module.css"
import { PortableText } from "@portabletext/react"
import { Link } from "gatsby"
import ResponsiveImage from "../responsiveImage/ResponsiveImage"
import {
	generateCombinedClassName,
	generateId,
} from "../../helpers/componentConfig"

const RichTextEditor = ({ value, pageBuilder = false, parentClasses }) => {
	const classes = useStyle(defaultClasses, parentClasses)

	const combinedClassName = generateCombinedClassName(
		value,
		classes.container
	)
	const id = generateId(value)

	const [readMore, setReadMore] = useState(false)

	const components = {
		marks: {
			internalLink: ({ value, children }) => {
				const slug = value?.reference?.slug
				const href = `/${slug?.current}`

				return (
					<Link
						className={value?.stylisedLink ? "btnPrimary" : ""}
						to={href}
					>
						{children}
					</Link>
				)
			},
			homeLink: ({ _, children }) => {
				const href = `/`

				return <Link to={href}>{children}</Link>
			},
			externalLink: ({ value, children }) => {
				// Read https://css-tricks.com/use-target_blank/
				const { blank, href } = value

				return blank ? (
					<a
						href={href}
						target="_blank"
						rel="noreferrer"
						className={value?.stylisedLink ? "btnPrimary" : ""}
					>
						{children}
					</a>
				) : (
					<a
						href={href}
						className={value?.stylisedLink ? "btnPrimary" : ""}
					>
						{children}
					</a>
				)
			},
			file: ({ value, children }) => {
				return (
					<a
						href={value?.asset?.url}
						target="_blank"
						rel="noreferrer"
					>
						{children}
					</a>
				)
			},
			projectColours: ({ value, children }) => {
				return <span style={{ color: value.hex }}>{children}</span>
			},
		},
		types: {
			responsiveImage: ({ value }) => {
				return (
					<ResponsiveImage
						images={value}
						parentClasses={parentClasses}
					/>
				)
			},
			break: props => {
				const { style } = props.value
				if (style === "break") {
					return <hr className={classes.lineBreak} />
				}
				if (style === "readMore") {
					return (
						<div className={"btnSecondary " + classes.button}>
							<button
								onClick={() =>
									setReadMore(prevState => !prevState)
								}
								className={classes.readMore}
							>
								{readMore ? "Read More" : "Read Less"}
							</button>
						</div>
					)
				}
				return null
			},
			code: ({ value }) => {
				return (
					<div
						dangerouslySetInnerHTML={{
							__html: value.code,
						}}
					></div>
				)
			},
			table: ({ value }) => {
				return (
					<table className={classes.tableStyles}>
						<tbody>
							{value?.rows.map((row, rowIndex) => (
								<tr
									key={row._key}
									className={
										rowIndex === 0
											? classes.firstRowStyle
											: classes.otherRowsStyle
									}
								>
									{row.cells.map((cell, cellIndex) => (
										<td
											key={cellIndex}
											className={
												rowIndex === 0
													? classes.firstRowCell
													: classes.otherRowCell
											}
										>
											{cell}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				)
			},
		},
	}

	let content = value?._rawPortableTextEditor

	// find the index of readMore
	const findReadMoreIndex = content?.findIndex(
		({ _type, style }) => _type === "break" && style === "readMore"
	)

	// set initial readMore state based on findReadMoreIndex
	const initialReadMoreState = findReadMoreIndex >= 0

	// slice the array if readMore, or use the entire content if not found
	const contentBeforeReadMore =
		findReadMoreIndex >= 0 ? content?.slice(0, findReadMoreIndex) : content

	const contentAfterReadMore =
		findReadMoreIndex >= 0 ? content?.slice(findReadMoreIndex + 1) : null // You might want to handle the case where there's no "read more" differently

	return (
		<>
			<div
				className={`${combinedClassName} ${
					pageBuilder ? classes.padding : ""
				}`}
				id={id}
			>
				<section className={`rta ${classes.wrapper}`}>
					<PortableText
						value={contentBeforeReadMore}
						components={components}
					/>
					{readMore && (
						<>
							<PortableText
								value={contentAfterReadMore}
								components={components}
							/>
							<div className={"btnSecondary " + classes.button}>
								<button
									onClick={() =>
										setReadMore(prevState => !prevState)
									}
									className={classes.readMore}
								>
									Read Less
								</button>
							</div>
						</>
					)}
					{!readMore && initialReadMoreState && (
						<div className={"btnSecondary " + classes.button}>
							<button
								onClick={() => setReadMore(true)}
								className={classes.readMore}
							>
								Read More
							</button>
						</div>
					)}
				</section>
			</div>
		</>
	)
}

export default RichTextEditor
