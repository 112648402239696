import React from "react"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./logoLinks.module.css"
import ResponsiveImage from "../responsiveImage/ResponsiveImage"

const LogoLinks = ({ items }) => {
	const classes = useStyle(defaultClasses)

	return (
		<>
			<div className={classes.container}>
				<div className={classes.wrapper}>
					{items?.map((item, index) => {
						return (
							<a
								href={item?.link}
								className={classes.logoLink}
								key={index}
								target="_blank"
								rel="noreferrer"
							>
								<ResponsiveImage images={item?.image} />
							</a>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default LogoLinks
