// LinkedinIcon.js
import React from "react"

const LinkedinIcon = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 20 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M2.1267 0C0.840769 0 0 0.913696 0 2.11462C0 3.28903 0.815722 4.22878 2.07736 4.22878H2.10176C3.41285 4.22878 4.22878 3.28903 4.22878 2.11462C4.20427 0.913696 3.41285 0 2.1267 0Z" />
		<path d="M0 5.63867H4.22878V18.325H0V5.63867Z" />
		<path d="M14.9495 5.63867C12.7075 5.63867 11.2041 7.72722 11.2041 7.72722V5.92959H7.04785V18.325H11.2038V11.4029C11.2038 11.0324 11.2309 10.6624 11.3407 10.3974C11.6412 9.6574 12.3249 8.89079 13.4731 8.89079C14.977 8.89079 15.5785 10.0275 15.5785 11.6939V18.325H19.7342V11.2177C19.7342 7.41037 17.6837 5.63867 14.9495 5.63867Z" />
	</svg>
)

export default LinkedinIcon
