// FacebookIcon.js
import React from "react"

const FacebookIcon = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 10 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M8.06573 3.27672H9.86716V0.138962C9.55637 0.0962048 8.48752 0 7.24271 0C4.6454 0 2.86617 1.63384 2.86617 4.63674V7.40037H0V10.9081H2.86617V19.7343H6.38023V10.909H9.13047L9.56706 7.40119H6.37941V4.98456C6.38023 3.97071 6.6532 3.27672 8.06573 3.27672Z" />
	</svg>
)

export default FacebookIcon
