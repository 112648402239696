// extracted by mini-css-extract-plugin
export var bird = "searchPopUp-module--bird--8d53d w-full h-full fill-tertiary opacity-[0.03]";
export var birdWrapper = "searchPopUp-module--birdWrapper--00f00 absolute -top-ms6 md_top-ms10 bottom-ms2 left-ms10 md_left-ms14 right-0 z-10";
export var closeButton = "searchPopUp-module--closeButton--8402b text-tertiary flex gap-ms-6 items-center justify-center z-20 [&>span]_hidden [&>span]_md_inline";
export var closeIcon = "searchPopUp-module--closeIcon--157c7 fill-tertiary w-ms1 h-ms1";
export var closeWrapper = "searchPopUp-module--closeWrapper--73774 ml-ms2 md_ml-ms6 cursor-pointer";
export var container = "searchPopUp-module--container--82599 mx-ms4 transform align-middle transition-all relative bg-white w-full max-w-[800px] h-[565px]";
export var inner = "searchPopUp-module--inner--bd42c w-full z-20";
export var input = "searchPopUp-module--input--9b4fb border-solid border border-neutral-300 outline-none py-ms-4 px-base w-full";
export var listItem = "searchPopUp-module--listItem--dfd7f transform transition-color duration-300";
export var listItemSelected = "searchPopUp-module--listItemSelected--f206d bg-light";
export var returnedLinks = "searchPopUp-module--returnedLinks--682f6 mt-ms2";
export var title = "searchPopUp-module--title--16577 mb-ms2 font-serif text-lg";
export var unorderedList = "searchPopUp-module--unorderedList--51439 flex flex-col gap-base";
export var wrapper = "searchPopUp-module--wrapper--3dfdd p-ms4 mx-auto flex flex-row";