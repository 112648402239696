export function generateCombinedClassName(value, classes) {
	const { componentSpacing, customClass } =
		value?.componentConfiguration || {}

	const classNames = []

	if (componentSpacing) {
		classNames.push("componentSpacing")
	}

	if (customClass) {
		classNames.push(customClass)
	}

	classNames.push(classes)

	return classNames.join(" ")
}

export function generateId(value) {
	return value?.componentConfiguration?.AnchorId || ""
}
