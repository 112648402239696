import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./searchItem.module.css"

const SearchItem = ({ result }) => {
	const classes = useStyle(defaultClasses)

	const imageData = getImage(
		result?.seo?.defaultMeta?.image?.asset?.gatsbyImageData
	)

	const maxLength = 40

	// Truncate the title if it exceeds the maxLength
	const truncatedText =
		result?.title.length > maxLength
			? `${result?.title.substring(0, maxLength)}...`
			: result?.title

	return (
		<>
			<Link
				to={
					result?._type === "postPage"
						? `/news/${result?.slug?.current}`
						: `/${result?.slug?.current}`
				}
				className={classes.container}
			>
				<div className={classes.imageContainer}>
					<GatsbyImage image={imageData} className={classes.image} />
				</div>
				<div className={classes.link}>{truncatedText}</div>
			</Link>
		</>
	)
}

export default SearchItem
