import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

const DialogModule = ({ children, isOpen, setIsOpen }) => {
	function closeModal() {
		setIsOpen(false)
	}
	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-[80]"
					onClose={closeModal}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-80" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						{children}
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default DialogModule
