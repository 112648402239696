import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./announcementBar.module.css"
import RichTextEditor from "../rta/Rta"

const AnnouncementBar = () => {
	const classes = useStyle(defaultClasses)

	const data = useStaticQuery(graphql`
		query announcement {
			sanityAnnouncement {
				live
				backgroundColour {
					hex
				}
				title {
					portableTextEditor {
						list
						style
						_type
						children {
							marks
							text
							_type
						}
					}
					_rawPortableTextEditor(resolveReferences: { maxDepth: 5 })
				}
				message {
					portableTextEditor {
						list
						style
						_type
						children {
							marks
							text
							_type
						}
					}
					_rawPortableTextEditor(resolveReferences: { maxDepth: 5 })
				}
			}
		}
	`)

	const info = data?.sanityAnnouncement

	// Check if window is defined (running in the browser) before using sessionStorage
	const [isClosed, setIsClosed] = useState(
		typeof window !== "undefined" &&
			sessionStorage.getItem("announcementBarClosed") === "true"
	)

	const handleClose = () => {
		setShow(false)

		// Check if window is defined before using sessionStorage
		if (typeof window !== "undefined") {
			// Set a timer for 5 seconds (adjust as needed)
			const timer = setTimeout(() => {
				setIsClosed(true)
				sessionStorage.setItem("announcementBarClosed", "true")
			}, 5000) // 5000 milliseconds = 5 seconds

			// Clear the timer if the component unmounts or isClosed becomes true
			return () => {
				clearTimeout(timer)
			}
		}
	}

	// show bar state
	const [show, setShow] = useState(false)

	useEffect(() => {
		const timerId = setTimeout(() => {
			setShow(true)
		}, 2000)

		// Clear the timer when the component unmounts or when the dependency array changes
		return () => clearTimeout(timerId)
	}, [])

	return (
		<>
			{!isClosed && info?.live && (
				<div
					className={`${classes.container} ${
						show ? classes.show : classes.hide
					} announcementBar`}
					style={{ backgroundColor: info?.backgroundColour?.hex }}
				>
					<div className={classes.wrapper}>
						<div className={classes.inner}>
							{info?.title && (
								<div className={classes.title}>
									<RichTextEditor
										value={info?.title}
										parentClasses={{
											wrapper: "w-full",
										}}
									/>
								</div>
							)}
							<div className={classes.message}>
								<RichTextEditor
									value={info?.message}
									parentClasses={{
										wrapper: "w-full",
									}}
								/>
							</div>
						</div>

						<div className={classes.closeWrapper}>
							<button
								onClick={handleClose}
								className={classes.closeButton}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									className={classes.closeIcon}
								>
									<path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z" />
								</svg>
								close
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default AnnouncementBar
