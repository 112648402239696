// extracted by mini-css-extract-plugin
export var closeButton = "announcementBar-module--closeButton--0b146 text-white flex gap-ms-6 items-center justify-center z-20";
export var closeIcon = "announcementBar-module--closeIcon--b8574 fill-white w-ms1 h-ms1";
export var closeWrapper = "announcementBar-module--closeWrapper--63363 cursor-pointer self-start";
export var container = "announcementBar-module--container--46b6c fixed w-full z-50 transform transition-all duration-700";
export var hide = "announcementBar-module--hide--fcfd1 -bottom-[100%]";
export var inner = "announcementBar-module--inner--091be flex flex-col lg_flex-row";
export var message = "announcementBar-module--message--4dc1d";
export var show = "announcementBar-module--show--c5669 bottom-0";
export var title = "announcementBar-module--title--1bdaf max-w-[30ch] mr-ms2 mb-ms2 lg_mb-0";
export var wrapper = "announcementBar-module--wrapper--55573 max-w-screen-2xl mx-auto w-full flex justify-between items-center relative py-ms1 px-ms1";